import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import logo from "../assets/images/logo.png";
import { fetchWrapper } from "../services/api";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

const Login = ({ onLogin, onShowToast }) => {
  const navigate = useNavigate();

  const handleLogin = (event) => {
    event.preventDefault();
    const dataForm = new FormData(event.currentTarget);
    const data = {
      username: dataForm.get("username"),
      password: dataForm.get("password"),
    };

    fetchWrapper
      .post("/sign-in/", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      })
      .then((response) => {
        onLogin({
          token: response.data.data.access_token,
          roles: response.data.roles,
          currentUser: response.data.name,
        });
        navigate("/");
      })
      .catch((err) => {
        onShowToast(
          "Error",
          "Ocurrio un error al traer el historial." + err.response.data.error
            ? err.response.data.error
            : err.response.data.message,
          "error"
        );
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      const form = event.target.form;
      const submitButton = form.querySelector('button[type="submit"]');

      if (submitButton) {
        submitButton.click();
      }
    }
  };

  return (
    <div className="login-container">
      <div className="left">
        <img className="logo" src={logo} alt="Logo" />
      </div>
      <div className="right">
        <Box component="form" onSubmit={handleLogin}>
          <div className="form-container">
            <h2 style={{ color: "#333333" }}>Iniciar Sesión</h2>
            <div>
              <TextField
                label="Usuario"
                type="text"
                name="username"
                id="username"
                variant="outlined"
                fullWidth
                required
                margin="dense"
                onKeyDown={handleKeyPress}
              />
            </div>
            <div>
              <TextField
                label="Contraseña"
                type="password"
                name="password"
                id="password"
                variant="outlined"
                fullWidth
                margin="dense"
                required
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="button-section">
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  width: "100%",
                  height: "38px",
                }}
                variant="contained"
                type="submit"
              >
                Entrar
                <KeyboardArrowRightIcon />
              </Button>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Login;
