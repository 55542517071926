import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { InactivityProvider } from "./components/Inactivity";

export const PrivateRoute = ({ token, handleLogout }) => {
  if (!token) {
    handleLogout();
    return <Navigate to="/login" />;
  }

  return (
    <InactivityProvider handleLogout={handleLogout} >
      <Outlet />
    </InactivityProvider>
  );
};
